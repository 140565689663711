import {useParams} from 'react-router-dom'
import { useState, useEffect } from 'react';
import { Link} from 'react-router-dom'; 
// import questionBank from '../api/questionBank.json'
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

export default function Quiz() {

  const { id } = useParams();
  const questionCountLimit = 45;
  const [questionIndex, setquestionIndex] = useState(0);
  // const [question, setQuestion] = useState( questionBank.find(q => q.id === parseInt(id)).questionbank[questionIndex]);
  const [questionBank, setQuestionBank] = useState( []);
  const [question, setQuestion] = useState( []);
  const [isCorrect, setIsCorrect] = useState(null);
  const [inCorrect, setInCorrect] = useState(null);
  const [score, setScore] = useState(0);
  const [isNextBtnDisabled, setisNextBtnDisabled] = useState(true);
  const [isOkBtnDisabled, setisOkBtnDisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');
  const [answerStatus, setAnswerStatus] = useState("");
  const [isShowResult, setIsShowResult] = useState(false);
 
  const [percentage, setPercentage] = useState(0);
  
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  

  
  useEffect(() => {
    
    const getQuestions = async () => {
      try {
        const response = await axios.post(`${apiUrl}`, { action: "get_question_bank",qtid:id, count_limit:questionCountLimit });
        if(response.data.status === "success"){
          //  console.log(response.data.result_data);
           const questionSet = response.data.result_data.questionbank;
           setQuestionBank(questionSet);
           setQuestion(questionSet[questionIndex]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    getQuestions();
    // eslint-disable-next-line
},[]);
  



  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setisOkBtnDisabled(false);
  }
  const handleCheckAnswer = () =>{
   
    if(selectedOption === question.Checked){
      setIsCorrect(true);
      setAnswerStatus("Your Answer is correct");
      setScore(prevScore => prevScore+1);
    }
    else {
      setIsCorrect(false);
      setInCorrect(true);
      setAnswerStatus("Your Answer is wrong. Correct answer is "+question.Checked);
    }
    setisNextBtnDisabled(false);
    setisOkBtnDisabled(true);
    setIsInputDisabled(true);
 
    
  }

  const handleNext = () => {
   
    if(questionIndex < questionCountLimit - 1){
      setquestionIndex(prevIndex => prevIndex + 1);
      setQuestion(questionBank[questionIndex + 1])
      setSelectedOption("");
      setIsCorrect(null);
      setAnswerStatus("");
      setisOkBtnDisabled(true);
      setisNextBtnDisabled(true);
      // setRemainingTime(10);
      setIsInputDisabled(false);
    }
    else {
      setInCorrect(questionCountLimit - score);
      setIsShowResult(true);
    }


    setPercentage(((100/questionCountLimit)*score).toFixed());
  } 

  return (
    <>
    {!isShowResult ? (
      <div className="w-full px-5 md:max-w-[900px] my-8 mx-auto">
        <h2 className="text-xl sm:text-2xl md:text-3xl" data-testid="question">{questionIndex+1}.  {question.Question}</h2>
        <div className="options grid grid-cols-1 gap-4 ">
        <div className="form-check mt-5 flex items-center gap-2">
          <input className="max-w-5	w-full h-5 bg-black checked:bg-black cursor-pointer" disabled={isInputDisabled} checked={selectedOption === 1} onChange={()=>handleOptionSelect(1)} name="options" data-testid="option-1"  type="radio" id={question.Option1} value={question.Option1} />
          <label className="form-check-label ml-2" htmlFor={question.Option1}>
            {question.Option1}
          </label>
        </div>
      <div className="form-check flex items-center gap-2">
        <input className="max-w-5	w-full h-5 bg-black checked:bg-black cursor-pointer" disabled={isInputDisabled} checked={selectedOption === 2} onChange={()=>handleOptionSelect(2)} name="options" data-testid="option-2"  type="radio" id={question.Option2} value={question.Option2} />
        <label className="form-check-label ml-2" htmlFor={question.Option2}>
          {question.Option2}
        </label>
      </div>
      <div className="form-check flex items-center gap-2">
        <input className="max-w-5 w-full h-5 bg-black checked:bg-black cursor-pointer" disabled={isInputDisabled} checked={selectedOption === 3} onChange={()=>handleOptionSelect(3)} name="options" data-testid="option-3"  type="radio" id={question.Option3} value={question.Option3} />
        <label className="form-check-label ml-2" htmlFor={question.Option3}>
          {question.Option3}
        </label>
      </div>
      <div className="form-check flex items-center gap-2">
        <input className="max-w-5	w-full h-5 bg-black checked:bg-black cursor-pointer" disabled={isInputDisabled} checked={selectedOption === 4} onChange={()=>handleOptionSelect(4)} name="options" data-testid="option-4"  type="radio" id={question.Option4} value={question.Option4} />
        <label className="form-check-label ml-2" htmlFor={question.Option4}>
          {question.Option4}
        </label>
      </div>
      </div>
      <div className="answers my-5">
          <div
            data-testid="validate-answer"
            className={`text-lg ${isCorrect ? "text-green-500" : "text-red-500"}`}
          >
            {answerStatus}
          </div>
        </div>
        <div className="button flex justify-center gap-8 my-8">
          <button
             className="bg-red-500 text-white px-4 py-2 rounded-md focus:outline-none disabled:opacity-50 hover:bg-red-700"
            onClick={handleCheckAnswer}
            data-testid="ok"
            disabled={isOkBtnDisabled}
          >
            Ok
          </button>
          <button
             className="bg-blue-500 text-white px-4 py-2 rounded-md focus:outline-none disabled:opacity-50 hover:bg-blue-700"
            onClick={handleNext}
            data-testid="next"
            disabled={isNextBtnDisabled}
          >
            Next
          </button>
       
      </div>
    </div>
    ) : (
      <div className="container w-9/12 md:max-w-[600px] mt-5 mx-auto px-4 py-8 text-center ResultsWrapper bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-xl font-bold text-blue-700 mb-4">Thanks for attempting the Exam</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col items-center">
          <h1 data-testid="score" className="text-3xl font-bold text-green-500">Your Score: {score}</h1>
          <h4 data-testid="correct-question" className="text-lg text-green-700 mt-2">Correct: {score}</h4>
        </div>
        <div className="flex flex-col items-center">
          <h2 data-testid="incorrect-question" className="text-3xl font-bold text-red-500">Incorrect: {inCorrect}</h2>
          <h4 data-testid="percentage-question" className="text-lg text-green-700 mt-2">Percentage: {percentage}%</h4>
        </div>
      </div>
      <Link to="/">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md focus:outline-none hover:bg-blue-700 mt-4"
          data-testid="attempt"
        >
          Go Home
        </button>
      </Link>
    </div>
    
    )}
      
    </>
  )

 
  
}