import React, {useState,useEffect} from 'react';
import { Link} from 'react-router-dom';
// import questionnaires from './../api/questionnaires.json';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export default function Home(){
    const [openIndex, setOpenIndex] = useState(null);
    const [questionTypes, setQuestionTypes] = useState([]);
   
   
    useEffect(() => {
        const getQuestionTypes = async () => {
          try {
            const response = await axios.post(`${apiUrl}`, { action: "get_question_types" });
            if(response.data.status === "success"){
                setQuestionTypes(response.data.result_data);
            }
            
          } catch (error) {
            console.error('Error fetching data:', error);
          }

        };
        getQuestionTypes();
        
    }, []);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
      };
      const templates = [
        {
          title: 'Notion Essentials Study Cards Pro',
          description: '🚀 Elevate Your Notion Mastery: The Ultimate Notion Essentials Badge Study Cards Pro 🚀',
          image: 'https://public-files.gumroad.com/vjrmfxiqjviqbuyu3edpmuzu9pja', 
          link: 'https://inagaraj.gumroad.com/l/notion-essentials-badge-study-pro', 
        },
        {
          title: 'Notion Settings & Sharing Study Cards Pro',
          description: '🚀 Earn Your Notion Settings & Sharing Badge with Confidence: Your Path to Mastery Starts Here 🚀',
          image: 'https://public-files.gumroad.com/65l1okx9gkngnz6zugdqz3r80il9', 
          link: 'https://inagaraj.gumroad.com/l/notion-settings-sharing-badge-pro', 
        },
        {
          title: 'Notion Advanced Badge Flash Cards Pro',
          description: '🚀 Unleash Your Notion Mastery: The Ultimate Notion Advanced Badge Flash Cards Pro 🚀',
          image: 'https://public-files.gumroad.com/uz1r6mpyjqvqtplmotvlknbpthro', 
          link: 'https://inagaraj.gumroad.com/l/notion-advanced-badge-flash-cards-pro', 
        },
      ];
     
    const licenseUrl = process.env.REACT_APP_LICENSE_URL;
      
  return (
      <>
      <div className="container mx-auto">
            <div className="flex items-center flex-col my-8 md:my-20 ">
                <h1 className="text-5xl md:text-6xl font-semibold text-center">Welcome to Badgeify</h1>
                <p className="text-center mt-8 my-4 text-muted-foreground font-light text-xl md:text-2xl md:max-w-[650px] px-4">Validate your expertise with Badgeify's practice exams and industry-recognized badges.</p>
                <p className="md:max-w-[800px] font-bold mx-auto leading-relaxed text-2xl text-gray-700 text-center mb-8">Your Path To Mastering Notion Certification.</p>
                <div className="flex flex-col gap-4 md:flex-row md:gap-10 justify-center items-center my-5">
                    <Link to={`/verify/`}><button className="bg-white text-black hover:bg-black hover:text-white border-black border-1 transition ease-in-out delay-150 h-[50px] border-[1px] text-md px-8 font-normal rounded-2xl" type="button">Get Started</button></Link>
                    <a target="_blank" rel="noreferrer" href={licenseUrl}>
                    <button className="bg-black text-white hover:bg-white hover:text-black border-black border-1 transition ease-in-out delay-150 h-[50px] border-[1px] text-md px-8 font-normal rounded-2xl">Buy License</button>
                    </a>
                </div>
            </div>
            <div className="flex items-center flex-col py-8 md:py-14" id="about">
                <h2 className="text-5xl md:text-4xl font-semibold text-center">About Badgeify</h2>
                <p className="text-center my-8 text-muted-foreground text-xl md:text-2xl md:max-w-[950px] px-4">At Badgeify, we're passionate about helping you master Notion and validate your skills through certification. Our platform offers three types of certification exams: Notion Essentials, Notion Settings & Sharing, and Notion Advanced. Each exam consists of 60 questions designed to test your knowledge comprehensively.</p>
            </div>
            <div className="container mx-auto md:max-w-[1000px] px-4 py-8 flex flex-col items-center md:flex-row md:space-x-8">
                <div className="w-full bg-white rounded-lg shadow-md p-6 flex flex-col items-center space-y-4 border border-black">
                    <h2 className="text-5xl md:text-4xl font-semibold text-center">Validate Your Notion Skills</h2>
                    <h3 className="text-3xl md:text-2xl text-center">Earn Badges & Boost Your Career</h3>
                    <p className="my-8 text-lg text-gray-700 text-center  px-4  leading-relaxed">
                        Verifiable skills are key in today's job market. Earn Notion badges to showcase your proficiency in this powerful productivity tool, setting you apart from the competition and boosting your earning potential. These badges validate your understanding of core features, advanced sharing, and complex workflows, making you a Notion pro!
                    </p>
                    <a href="/#get-certified"><button className="bg-black border border-black hover:bg-white hover:text-black text-white px-4 py-2 rounded-md focus:outline-none">Learn More</button></a>
                </div>
               
            </div>
            <div className="flex items-center flex-col py-8 md:py-12" id="how-it-works">
                <div className="container md:max-w-[1100px] mx-auto px-4 py-10" >
                    <h2 className="text-3xl mb-5 md:text-5xl md:mb-10 font-semibold text-center">How does it work?</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="flex flex-col items-center space-y-4 p-6  bg-white rounded-lg shadow-md border border-black">
                            <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                            <h3 className="text-3xl font-bold text-gray-800">Sign Up & Explore</h3>
                            <p className="text-lg text-gray-700 text-center">Browse a wide range of certifications relevant to your career goals.</p>
                        </div>
                        <div className="flex flex-col items-center space-y-4 p-4 bg-white rounded-lg shadow-md border border-black">
                            <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg> 
                            <h3 className="text-3xl font-bold text-gray-800">Sharpen Your Skills</h3>
                            <p className="text-lg text-gray-700 text-center">Access high-quality practice exams and comprehensive study materials.</p>
                        </div>
                        <div className="flex flex-col items-center space-y-4 p-4 bg-white rounded-lg shadow-md border border-black">
                            <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H357.9C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z"/></svg>
                            <h3 className="text-3xl font-bold text-gray-800">Get Certified</h3>
                            <p className="text-lg text-gray-700 text-center">Take exams and earn badges upon successful completion. Showcase your achievements.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center flex-col my-8 md:my-20 md:mb-0">
                <section className="bg-gray-800 text-white py-16 px-8 w-full">
                    <h2 className="text-3xl mb-4 md:text-5xl md:mb-10 font-semibold text-center">Unlock Lifetime Access with a Single Purchase</h2>
                    <p className="md:max-w-[800px] mx-auto text-xl leading-relaxed text-center">Get lifetime access to three badge certifications: Notion Essentials, Settings and Sharing, and Notion Advanced. Our perpetual license ensures indefinite use after a single payment, along with one year of free updates. Experience convenience and sustainability with Badgeify.</p>
                    <div className="flex justify-center mt-8">
                        <a href={licenseUrl} target="_blank" rel="noreferrer" className="px-6 py-3 bg-white text-black font-bold rounded-md relative shadow-md hover:bg-transparent border border-white hover:text-white">Buy Now
                        <span className="text-center left-0 w-full absolute text-xs bottom-[-20px] font-semibold text-muted-foreground text-white">Lifetime Update</span></a>
                    </div>
                </section>
            </div>
            <div className="flex items-center flex-col py-8 md:py-12 md:mb-12" id="resources">
                <h2 className="text-2xl md:text-5xl font-semibold text-center my-5">Resources for Notion Certification Preparation</h2>
                <p className="md:max-w-[800px] mx-auto leading-relaxed text-2xl text-gray-700 text-center mb-8">Get ready for your exams with our study guides and practice tests. Explore additional learning resources to boost your Notion proficiency.</p>
                
                <div className="w-11/12 md:w-10/12 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
                {templates.map((template, index) => (

                        <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden">
                            <a href={template.link} target="_blank" rel="noreferrer"><img width="100%" height="100%" src={template.image} alt={template.title} className="w-full  object-cover transition ease-in-out duration-500 hover:scale-95" /></a>
                            <div className="p-4">
                                <h3 className="text-lg font-bold mb-5 text-center">{template.title}</h3>
                                <p className="text-black-700 mb-6 text-center">{template.description}</p>
                                <a href={template.link} target="_blank" rel="noreferrer" className=" block text-center px-4 py-2 bg-black text-white font-bold rounded-md hover:bg-transparent hover:text-black border border-black">
                                    Buy Template & Prepare
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
                </div>
            <section className="bg-black text-white py-10 px-4">
                <div className="container mx-auto flex justify-center items-center">
                    <div className="text-center">
                    <h2 className="text-3xl mb-2 md:text-5xl md:mb-10 font-semibold text-center">Ready to showcase your Notion skills?</h2>
                    <p className="text-xl mb-8">Enroll now and earn your badges!</p>
                    <a  href={licenseUrl} target="_blank" rel="noreferrer" className="inline-block px-6 py-3 bg-white text-black font-bold rounded-md shadow-md hover:bg-black hover:text-white border border-white">
                        Enroll Now
                    </a>
                    </div>
                </div>
            </section>   
            <div className="flex items-center flex-col py-8 md:py-12 pb-5 md:pb-0 " id="get-certified">
                <h2 className='text-2xl md:text-5xl font-semibold text-center my-5'>Certification Badges</h2>
                <div className="w-11/12 md:w-10/12 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
                {questionTypes.map((type) => (
                        <div key={type.id} className="md:my-10">
                            <div className="p-4  bg-white shadow-md rounded-lg overflow-hidden border-black border">
                                <img width="100%" height="100%" src={type.question_type_image_link} className="w-6/12 mx-auto" alt={type.question_type_name} />
                                <div className="p-4">
                                <div className="text-xl text-center font-semibold mb-2">{type.question_type_name}</div>
                                <div className="text-gray-600 mb-4 text-center">No. of Questions: {type.no_of_questions}</div>
                                <Link to={`/exam/${type.id}`}>
                                    <button className="block mx-auto text-center bg-black hover:text-black hover:bg-white border-black border text-white font-bold py-2 px-4 rounded-lg">
                                    Attempt Exam
                                    </button>
                                </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
          
            <div className="m-full md:max-w-[1000px] mx-auto my-8 md:my-20 px-4">
                <h2 className="text-2xl md:text-5xl font-semibold text-center my-5">FAQs</h2>
                <div className="bg-white shadow-lg rounded-lg ">
                    <div className="border-b">
                        <div className="p-4 flex justify-between items-center cursor-pointer" onClick={() => handleToggle(1)}>
                            <h3 className="text-lg font-semibold">Q: How many questions are there in each exam?</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${openIndex === 1 ? 'transform rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                        {openIndex === 1 && (
                            <p className="p-4 text-gray-700">A: Each exam consists of 60 questions.</p>
                        )}
                    </div>
                    <div className="border-b">
                        <div className="p-4 flex justify-between items-center cursor-pointer" onClick={() => handleToggle(2)}>
                            <h3 className="text-lg font-semibold">Q: Can I retake the exam if I don't pass?</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${openIndex === 2 ? 'transform rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                        {openIndex === 2 && (
                            <p className="p-4 text-gray-700">A: Yes, you can retake the exam.</p>
                        )}
                    </div>
                    <div className="border-b">
                        <div className="p-4 flex justify-between items-center cursor-pointer" onClick={() => handleToggle(3)}>
                            <h3 className="text-lg font-semibold">Q: What is the format of the Notion certification exams?</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${openIndex === 3 ? 'transform rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                        {openIndex === 3 && (
                            <p className="p-4 text-gray-700">A: The exams consist of multiple-choice questions designed to test your comprehensive understanding of Notion's features and functionalities.</p>
                        )}
                    </div>
                    <div className="border-b">
                        <div className="p-4 flex justify-between items-center cursor-pointer" onClick={() => handleToggle(4)}>
                            <h3 className="text-lg font-semibold">Q: What happens if I fail an exam?</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${openIndex === 4 ? 'transform rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                        {openIndex === 4 && (
                            <p className="p-4 text-gray-700">A: If you fail an exam, you can retake it. We offer up to three retakes for each exam to help you succeed.</p>
                        )}
                    </div>
                    <div className="border-b">
                        <div className="p-4 flex justify-between items-center cursor-pointer" onClick={() => handleToggle(5)}>
                            <h3 className="text-lg font-semibold">Q: Is there a time limit to access the exams?</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${openIndex === 5 ? 'transform rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                        {openIndex === 5 && (
                            <p className="p-4 text-gray-700">A: No, once you purchase a license, you have lifetime access to the exams, including any future updates.</p>
                        )}
                    </div>
                    <div className="border-b">
                        <div className="p-4 flex justify-between items-center cursor-pointer" onClick={() => handleToggle(6)}>
                            <h3 className="text-lg font-semibold">Q: Are there any prerequisites for taking the Notion certification exams?</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${openIndex === 6 ? 'transform rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                        {openIndex === 6 && (
                            <p className="p-4 text-gray-700">A: There are no formal prerequisites, but we recommend having a basic understanding of Notion and completing the corresponding study materials before taking the exams.</p>
                        )}
                    </div>
                    <div className="border-b">
                        <div className="p-4 flex justify-between items-center cursor-pointer" onClick={() => handleToggle(7)}>
                            <h3 className="text-lg font-semibold">Q: Do you offer support if I have questions or need help with the study materials?</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${openIndex === 7 ? 'transform rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                        {openIndex === 7 && (
                            <p className="p-4 text-gray-700">A: Yes, support is available to assist you. You can contact us via <a className="underline underline-offset-2" href="mailto:freelancernagaraj143@gmail.com">email</a></p>
                        )}
                    </div>
                </div>
            </div>
            
            <footer className="bg-gray-900 text-white py-4 px-4">
                <div className="container mx-auto flex flex-col sm:flex-row justify-center sm:justify-between items-center">
                    <p className="text-sm">Copyright &copy; 2024 Badgeify. All rigthts reserved</p>
                    <p className="text-sm"><a className="underline underline-offset-2" href="mailto:freelancernagaraj143@gmail.com">Contact: freelancernagaraj143@gmail.com</a></p>
                    
                </div>
            </footer>
        </div>
      
      </>
  );

}